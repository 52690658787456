import Vue from "vue";
import titleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
import { UploaderPlugin } from '@syncfusion/ej2-vue-inputs';
import { fromText as getOwnerTypeFromText } from "@/types/attachmentTypes/AttachmentOwnerType";
import AttachmentAvailabilityWrapper from "@/components/Attachments/AttachmentAvailabilityWrapper.vue";
Vue.use(UploaderPlugin);
export default Vue.extend({
    components: { AttachmentAvailabilityWrapper },
    mixins: [titleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("attachments.create.title"),
            attachment: {
                attachmentTypeId: null,
                name: "",
                fileName: "",
                fileContent: ""
            },
            attachmentTypes: [],
            validationOptions: {
                rules: {
                    attachmentTypeId: {
                        required: true
                    },
                    name: {
                        required: true
                    },
                    fileName: {
                        required: true
                    }
                }
            }
        };
    },
    methods: {
        attachmentSelected(args) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const fileContent = reader.result != null ? reader.result.toString() : null;
                if (fileContent != null) {
                    const fileContentParts = fileContent.split(";base64,");
                    this.attachment.fileName = args.filesData[0].name;
                    this.attachment.fileContent = fileContentParts.length == 2 ? fileContentParts[1] : fileContent;
                }
            };
            reader.readAsDataURL(args.filesData[0].rawFile);
        },
        create() {
            switch (this.$route.params.ownerType) {
                case "customers":
                    return this.$store.dispatch("customer/createAttachment", {
                        customerId: this.$route.params.ownerId,
                        attachment: this.attachment
                    });
                case "employees":
                    return this.$store.dispatch("employee/createAttachment", {
                        employeeId: this.$route.params.ownerId,
                        attachment: this.attachment
                    });
                default:
                    throw new Error(`The owner type '${this.$route.params.ownerType}' is not managed.`);
            }
        },
        createSuccess() {
            this.$toast.showSuccessToast(this.$t("attachments.create.toastSuccessTitle"), this.$t("attachments.create.toastSuccessContent", {
                name: this.attachment.name
            }));
            return this.$router.push(`/${this.$route.params.ownerType}/${this.$route.params.ownerId}/attachments`);
        },
        cancel() {
            return this.$router.push(`/${this.$route.params.ownerType}/${this.$route.params.ownerId}/attachments`);
        },
        getAvailableAttachmentTypes(attachmentTypes) {
            return attachmentTypes.filter(attachmentType => attachmentType.deletedAt == null);
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            const ownerType = getOwnerTypeFromText(this.$route.params.ownerType);
            this.attachmentTypes = this.getAvailableAttachmentTypes(await this.$store.dispatch("attachmentType/getAll", { ownerType: ownerType }));
        }
        catch (errors) {
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push(`${this.$route.params.ownerType}/${this.$route.params.ownerId}/attachments/`);
        }
        finally {
            this.hideSpinner();
        }
    }
});
